<template>
  <div
    class="modal fade"
    id="modalViewUploadPDD"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalViewUploadPDDLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sx" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="d-flex justify-content-between p-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span class="uppercase ml-5" v-if="type === 'NamVien'">Chi tiết phiếu nằm viện</span>
            <span v-if="type === 'NhapVien'" class="uppercase ml-5">Chi tiết phiếu nhập viện</span>
          </div>
          <div
            v-if="modeUpdate === null"
            @click="modeUpdate = 1"
            class="mt-2.5 cursor-pointer mr-2"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 8.98568C15.5511 8.98568 15.3603 9.06 15.2197 9.19228C15.079 9.32457 15 9.50399 15 9.69107V13.9234C15 14.1105 14.921 14.2899 14.7803 14.4222C14.6397 14.5545 14.4489 14.6288 14.25 14.6288H3.75C3.55109 14.6288 3.36032 14.5545 3.21967 14.4222C3.07902 14.2899 3 14.1105 3 13.9234V4.04798C3 3.8609 3.07902 3.68148 3.21967 3.5492C3.36032 3.41691 3.55109 3.3426 3.75 3.3426H8.25C8.44891 3.3426 8.63968 3.26828 8.78033 3.13599C8.92098 3.00371 9 2.82429 9 2.63721C9 2.45013 8.92098 2.27071 8.78033 2.13843C8.63968 2.00614 8.44891 1.93182 8.25 1.93182H3.75C3.15326 1.93182 2.58097 2.15478 2.15901 2.55163C1.73705 2.94849 1.5 3.48674 1.5 4.04798V13.9234C1.5 14.4846 1.73705 15.0229 2.15901 15.4197C2.58097 15.8166 3.15326 16.0395 3.75 16.0395H14.25C14.8467 16.0395 15.419 15.8166 15.841 15.4197C16.2629 15.0229 16.5 14.4846 16.5 13.9234V9.69107C16.5 9.50399 16.421 9.32457 16.2803 9.19228C16.1397 9.06 15.9489 8.98568 15.75 8.98568ZM4.5 9.52177V12.5126C4.5 12.6997 4.57902 12.8791 4.71967 13.0114C4.86032 13.1437 5.05109 13.218 5.25 13.218H8.43C8.5287 13.2185 8.62655 13.2007 8.71793 13.1656C8.80931 13.1305 8.89242 13.0788 8.9625 13.0134L14.1525 8.12511L16.2825 6.16414C16.3528 6.09856 16.4086 6.02055 16.4467 5.93459C16.4847 5.84863 16.5043 5.75643 16.5043 5.66331C16.5043 5.5702 16.4847 5.478 16.4467 5.39204C16.4086 5.30608 16.3528 5.22807 16.2825 5.16249L13.1025 2.13639C13.0328 2.07027 12.9498 2.01779 12.8584 1.98198C12.767 1.94617 12.669 1.92773 12.57 1.92773C12.471 1.92773 12.373 1.94617 12.2816 1.98198C12.1902 2.01779 12.1072 2.07027 12.0375 2.13639L9.9225 4.13263L4.7175 9.02095C4.64799 9.08686 4.59299 9.16503 4.55567 9.25097C4.51835 9.33692 4.49943 9.42894 4.5 9.52177ZM12.57 3.6318L14.6925 5.62805L13.6275 6.62969L11.505 4.63345L12.57 3.6318ZM6 9.81098L10.4475 5.62805L12.57 7.62429L8.1225 11.8072H6V9.81098Z"
                fill="black"
              />
            </svg>
          </div>
          <button
            type="button"
            class="close bg-white border-0"
            id="closeModal"
            @click="closeModal(false)"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="mb-3 row">
            <div class="col-md-4">
              <p class="mb-1 fs-16 txt-grey-900 fw-700 mt-2">Tên phiếu:</p>
            </div>
            <div class="col-md-8">
              <el-input
                v-if="modeUpdate"
                v-model="form.name"
                class="w-100 col-md-5"
                placeholder="Nhập Tên"
              ></el-input>
              <div v-else class="fw-500 txt-grey-900 mt-2">{{ form.name }}</div>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-md-4">
              <p class="mb-1 fs-16 txt-grey-900 fw-700 mt-2">Ngày giờ tạo phiếu:</p>
            </div>
            <div class="col-md-8">
              <input
                v-if="modeUpdate"
                v-model="form.date"
                type="datetime-local"
                class="form-control fw-500 txt-grey-900"
              />
              <div v-else class="fw-500 txt-grey-900 mt-2">{{ formatDate(form.date) }}</div>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-md-4">
              <p
                v-if="type === 'NamVien'"
                class="mb-1 fs-16 txt-grey-900 fw-700 mt-2"
              >Bác sĩ điều trị:</p>
              <p v-else class="mb-1 fs-16 txt-grey-900 fw-700 mt-2">Bác sĩ khám bệnh:</p>
            </div>
            <div class="col-md-8">
              <el-select
                :disabled="!modeUpdate"
                v-model="form.doctor_id"
                allow-create
                filterable
                remote
                reserve-keyword
                :placeholder="!modeUpdate ? '' : 'Tìm kiếm tên bác sĩ'"
                class="w-100"
                :remote-method="getDoctor"
                :loading="loadingDoctor"
              >
                <el-option
                  v-for="item in doctors"
                  :key="item.id + 'dtr'"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="flex justify-center" v-if="!modeUpdate">
            <a
              v-if="form.pdf_url"
              href="javascript:;"
              @click="openFile(form && form.pdf_url)"
              class="txt-pri fs-14 text-decoration-underline"
            >Xem Phiếu</a>
          </div>

          <div class="mb-3 row">
            <div class="col-md-9">
              <div v-if="modeUpdate">
                <p class="mt-3 fs-14" v-if="!file">{{ getFilename(form.pdf_url) }}</p>
                <p v-else>{{ getFilename(file.name) }}</p>
              </div>
            </div>
            <div class="col-md-3">
              <div v-if="modeUpdate">
                <div
                  @click="onShowPickFilePDD"
                  class="mt-3 ml-4 fs-16 text-blue-900 underline cursor-pointer hover:text-blue-500"
                >Thay đổi</div>
                <input
                  id="file-modeUpdate"
                  class="d-none"
                  name="file-modeUpdate"
                  type="file"
                  accept="jpeg, .png, .jpg, .gif, .svg, .doc, .docx, .pdf, .xls, .xlsx, .xlsm, .ppt, .pptx, .heic"
                  @change="onSelectFilePDD"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn bg-white text-pri"
            type="button"
            v-if="modeUpdate"
            @click="closeUpdate"
          >Hủy</button>
          <button
            v-if="modeUpdate"
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
          >Lưu</button>
        </div>
      </div>
    </div>
  </div>
</template>
      <script>
import _ from 'lodash'
import moment from 'moment'
export default {
  name: 'ModalViewPDD',
  props: ['data', 'type', 'loading'],
  data () {
    return {
      loadingDoctor: false,
      modeUpdate: null,
      is_loading: false,
      doctors: [],
      file: null,
      form: {
        pdf_url: '',
        pdf: null,
        doctor_id: '',
        medical_record_id: null,
        name: '',
        date: moment().format('YYYY-MM-DD HH:mm')
      }
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.form.name = value.name
        this.form.doctor_id = value.doctor_id || value?.doctor_name
        this.form.pdf_url = value.pdf_url
        this.form.medical_record_id = value.medical_record_id
        this.form.date = value.date
      }
    }
  },
  mounted () {
    this.getDoctor()
    let self = this
    this.$nextTick(function () {
      window.$('#modalViewUploadPDD').on('hidden.bs.modal', function (e) {
        self.$emit('refreshList')
        self.$emit('refresh')
        self.modeUpdate = null
      })
      window.$('#modalViewUploadPDD').on('hide.bs.modal', function (e) {
        if (self.modeUpdate) {
          if (
            !confirm(
              'Các thay đổi có thể chưa được lưu. Bạn có muốn lưu không?'
            )
          ) {
            return true
          } else self.onSubmit()
        }
      })
    })
  },
  methods: {
    getFilename (fullPath) {
      if (!_.isString(fullPath)) return
      return fullPath.split('/').pop()
    },
    getDoctor (search = null) {
      if (this.$globalClinic) {
        if (this.$globalClinic?.org_id) {
          this.getOrgDoctor(search)
        } else {
          this.getClinicDoctor(search)
        }
      }
    },
    async getOrgDoctor (search = null) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getOrgDoctor(this.$globalClinic?.org_id, params)
        .then(r => {
          this.doctors = r.data
          const hasMe = this.doctors.find(d => d.user_id === this.$user.id)
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = r.meta
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    async getClinicDoctor (search) {
      const params = {
        limit: 50
      }
      if (search) {
        params.search = search
      }
      this.loadingDoctor = true
      await this.$rf
        .getRequest('DoctorRequest')
        .getClinicDoctor(this.$globalClinic?.id, params)
        .then(r => {
          this.doctors = r.data.data
          this.doctors.map(d => {
            d.name = d.doctor?.name
            d.user_id = d.doctor?.user_id
          })
          const hasMe = this.doctors.find(
            d => d.doctor_id === this.$user?.doctor.id
          )
          if (!hasMe && this.$user.doctor) this.doctors.push(this.$user.doctor)
          this.doctorPaging = {
            count: r.data.count,
            current_page: r.data.current_page,
            last_page: r.data.last_page,
            per_page: r.data.per_page,
            total: r.data.total
          }
        })
        .finally(() => {
          this.loadingDoctor = false
        })
    },
    closeUpdate () {
      this.$emit('refresh')
      this.modeUpdate = null
      this.file = null
    },
    openFile (url) {
      if (!url) return
      window.open(url)
    },
    closeModal (show) {
      window.$('#modalViewUploadPDD').modal(show ? 'show' : 'hide')
      this.modeUpdate = null
      this.file = null
    },
    onSubmit () {
      if (isNaN(this.form.doctor_id)) {
        this.form.doctor_name = this.form.doctor_id
        this.form.doctor_id = null
      }

      if (this.type === 'NamVien') {
        this.form.pdf = this.file
        this.$emit('onSubmit', this.form)
        this.modeUpdate = null
        this.file = null
      }
      if (this.type === 'NhapVien') {
        this.form.pdf = this.file
        this.$emit('onSubmit', this.form)
        this.modeUpdate = null
        this.file = null
      }
    },
    onSelectFilePDD (event) {
      this.file = event.target.files[0]
    },
    onShowPickFilePDD () {
      window.$('input[id="file-modeUpdate"]').trigger('click')
    },
    formatDate (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    }
  }
}
</script>
